// import mapboxgl from 'mapbox-gl';
import anime from 'animejs';
import { collect } from 'collect.js';

export default ({ center, lines, preview = false }) => ({
    map: null,
    initialised: false,
    showLogo: true,
    introRan: false,
    interactWithMap: false,
    locationMarkers: [],
    skipAnimations: false,
    center,
    lines,
    preview,

    async init() {
        const { default: mapboxgl } = await import('mapbox-gl');

        mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_TOKEN;

        this.skipAnimations = this.$store.introRan || this.preview;

        this.map = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/noordermeer/clgaxodxi00dj01p0tu1jq517',
            center,
            zoom: this.getInitialZoom(),
            minZoom: 3,
            maxZoom: 10,
            attributionControl: false,
        });

        this.map.on('load', () => {
            // this.map.zoomTo(this.getInitialZoom(), { duration: 0 })
            //
            // setTimeout(() => {
                this.initialised = true;

                if (this.skipAnimations) {
                    this.introRan = true;
                    this.interactWithMap = true;
                    this.drawBorderLines();
                    this.drawLocationPins();
                }

                if (this.preview) {
                    this.addPreviewMarkers();
                }
            // }, 1000)
        });

        this.map.on('zoom', () => {
            this.scaleLocationMarkers();
        });

        setTimeout(() => {
            this.showLogo = false;
        }, 1000);
    },

    getInitialZoom() {
        if (this.skipAnimations) {
            return this.getFinalZoom();
        }

        // todo: set according to viewport
        return 3;
    },

    scaleLocationMarkers() {
        const scalePercent = this.scale(this.map.getZoom(), 3, 8, 0.5, 1);
        this.locationMarkers.forEach((marker) => {
            const svgElement = marker.getElement().children[0];
            svgElement.style.transform = `scale(${scalePercent})`;
        });
    },

    scale(number, inMin, inMax, outMin, outMax) {
        const num =
            ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
        return Math.min(Math.max(num, outMin), outMax);
    },

    getFinalZoom() {
        const windowWidth = window.innerWidth;

        return this.scale(windowWidth, 320, 1200, 6.5, 8);
    },

    introAnimation() {
        if (!this.initialised || !this.map || this.introRan) {
            return;
        }

        this.introRan = true;
        this.$store.introRan = true;

        this.drawBorderLines();

        const duration = 4000;

        setTimeout(() => {
            this.map.zoomTo(this.getFinalZoom(), { duration });
        }, 100);

        setTimeout(() => {
            this.interactWithMap = true;
        }, duration * 0.8);
    },

    get locations() {
        return collect(this.lines.so).filter((line) => line[2]);
    },

    getSOLineCoords() {
        return (
            collect(this.lines.so)
                // .merge(this.lines[0])
                // .sort((a, b) => a[0] - b[0])
                .reverse()
                .all()
        );
    },

    getABLineCoords() {
        return collect(this.lines.ab).reverse().all();
    },

    addBorderlineToMap(id, coordinates) {
        const borderLineString = {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates,
                    },
                },
            ],
        };

        this.map.addSource(id + '-source', {
            type: 'geojson',
            lineMetrics: true,
            data: borderLineString,
        });

        this.map.addLayer({
            id,
            type: 'line',
            source: id + '-source',
            paint: {
                'line-color': '#e2020d',
                'line-width': 1,
                'line-trim-offset': [0, this.skipAnimations ? 0 : 1],
            },
        });
    },

    drawBorderLines() {
        this.addBorderlineToMap('border-line-so', this.getSOLineCoords());
        this.addBorderlineToMap('border-line-ab', this.getABLineCoords());

        if (this.preview) {
            return;
        }

        this.drawLocationPins();

        if (this.skipAnimations) {
            return;
        }

        let steps = { step: 0 };
        let map = this.map;

        anime({
            easing: 'easeOutCubic',
            targets: steps,
            duration: 2500,
            // delay: 500,
            step: 1,
            update: function (anim) {
                map.setPaintProperty('border-line-so', 'line-trim-offset', [
                    0,
                    1 - steps.step,
                ]);
                map.setPaintProperty('border-line-ab', 'line-trim-offset', [
                    0,
                    1 - steps.step,
                ]);
            },
        });
    },

    async drawLocationPins() {
        const { default: mapboxgl } = await import('mapbox-gl');

        this.getLocationsCollection().features.forEach((feature, index) => {
            const el = document.createElement('a');
            el.href = feature.properties.url;
            el.title = feature.properties.description;
            el.ariaLabel = feature.properties.description;

            const icon = document.createElement('div');
            icon.className = 'location-pin';
            if (!this.skipAnimations) {
                icon.style.setProperty('--index', index);
            }
            el.appendChild(icon);

            // make a marker for each feature and add to the map
            this.locationMarkers.push(
                new mapboxgl.Marker(el, { anchor: 'center', offset: [0, 0] })
                    .setLngLat(feature.geometry.coordinates)
                    .addTo(this.map),
            );
        });

        this.scaleLocationMarkers();
    },

    getLocationsCollection() {
        const features = this.locations
            .map(([lng, lat, name, url]) => {
                return {
                    type: 'Feature',
                    properties: {
                        description: name,
                        url: url,
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: [lng, lat],
                    },
                };
            })
            .all();

        return {
            type: 'FeatureCollection',
            features,
        };
    },

    async addPreviewMarkers() {
        const { default: mapboxgl } = await import('mapbox-gl');

        this.lines.forEach((line) => {
            line.forEach(([lng, lat]) => {
                if (lng && lat) {
                    new mapboxgl.Marker({ color: 'black' })
                        .setLngLat([lng, lat])
                        .addTo(this.map);
                }
            });
        });

        // this.locations.forEach(([lng, lat]) => {
        //     new mapboxgl.Marker({ color: "blue" })
        //         .setLngLat([lng, lat])
        //         .addTo(this.map);
        // });

        this.map.addSource('location-preview-source', {
            type: 'geojson',
            data: this.getLocationsCollection(),
        });

        this.map.addLayer({
            id: 'location-preview',
            type: 'symbol',
            source: 'location-preview-source',
            layout: {
                'text-field': ['get', 'description'],
                'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
                'text-radial-offset': 0.5,
                'text-justify': 'auto',
            },
        });
    },

    destroy() {
        if (this.map) {
            this.map.remove();
        }
    },
});
