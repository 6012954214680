import Swup from 'swup';
import SwupProgressPlugin from '@swup/progress-plugin';
import SwupPreloadPlugin from '@swup/preload-plugin';
import SwupBodyClassPlugin from '@swup/body-class-plugin';

export default new Swup({
    plugins: [
        new SwupProgressPlugin({
            delay: 600,
        }),
        new SwupPreloadPlugin(),
        new SwupBodyClassPlugin({
            prefix: 'page-',
        }),
    ],
});
