export default ({
    url: streamUrl,
    id: blockId,
    gallery: galleryMode,
    thumb: thumbnailUrl,
}) => ({
    initialised: false,
    playerReady: false,
    playerInstance: null,
    activeGallerySlider: false,
    isLoading: true,
    playbackStarted: false,
    streamUrl,
    blockId,
    galleryMode,
    thumbnailUrl,

    async init() {
        if (this.galleryMode) {
            // if we're in the gallery, trigger enter and leave events on sliding
            this.$watch('currentSlideBlockId', () => {
                this.currentSlideBlockId === this.blockId
                    ? this.enterSlide()
                    : this.leaveSlide();
            });
        } else {
            this.$watch('$store.activePlayer', () => {
                this.setPlayState();
            });
        }

        this.$watch('$store.muted', () => {
            this.setPlayerMute();
        });
    },

    async initPlayer() {
        if (this.initialised) return;

        this.initialised = true;

        const { default: Hls } = await import('hls.js');

        this.playerInstance = document.createElement('video');

        if (this.galleryMode) {
            this.playerInstance.setAttribute(
                'class',
                'w-full h-full object-contain',
            );
        } else {
            this.playerInstance.setAttribute('class', 'w-full');
        }

        this.playerInstance.setAttribute('loop', true);
        this.playerInstance.setAttribute('muted', true);
        this.playerInstance.setAttribute('playsinline', true);
        this.playerInstance.setAttribute('disable-picture-in-picture', true);
        // this.playerInstance.setAttribute("poster", this.thumbnailUrl);

        if (this.playerInstance.canPlayType('application/vnd.apple.mpegurl')) {
            this.playerInstance.src = this.streamUrl;
        } else if (Hls.isSupported()) {
            const hls = new Hls({
                startLevel: 3, // 720p
            });
            hls.loadSource(this.streamUrl);
            hls.attachMedia(this.playerInstance);
        }

        this.playerInstance.addEventListener(
            'loadedmetadata',
            () => {
                this.playerReady = true;
                this.setPlayState();
            },
            { once: true },
        );

        this.playerInstance.addEventListener('playing', () => {
            this.playbackStarted = true;
            this.isLoading = false;
        });

        this.playerInstance.addEventListener('waiting', () => {
            this.isLoading = true;
        });

        this.playerInstance.addEventListener('error', (event) => {
            this.catchPlayError(event.error);
        });

        this.$refs.wrapper.appendChild(this.playerInstance);
        // });

        // catch media hardware keys
        navigator?.mediaSession?.setActionHandler('play', () => {});
        navigator?.mediaSession?.setActionHandler('pause', () => {});
    },

    async enterSlide() {
        this.activeGallerySlider = true;

        if (!this.initialised) {
            await this.initPlayer();
        }

        this.setPlayState();
    },

    leaveSlide() {
        this.activeGallerySlider = false;

        this.setPlayState();
    },

    enter() {
        this.activatePlayer();

        if (!this.initialised) {
            this.initPlayer();
        }
    },

    leave() {
        this.deactivatePlayer();
    },

    setPlayState() {
        this.isActivePlayer ? this.playPlayer() : this.pausePlayer();
    },

    playPlayer() {
        if (!this.playerInstance || !this.playerReady) {
            return;
        }

        this.playerInstance.currentTime =
            this.$store.playPositions[this.blockId] ?? 0;

        if (this.playerInstance.paused) {
            this.setPlayerMute();

            this.playerInstance.play().catch((error) => {
                this.catchPlayError(error);
            });
        }
    },

    pausePlayer() {
        if (!this.playerInstance) {
            return;
        }

        if (!this.playerInstance.paused) {
            // Update the play position only when actually pausing the player
            this.$store.playPositions[this.blockId] =
                this.playerInstance.currentTime ?? 0;

            this.playerInstance.pause();
        }
    },

    toggleMute() {
        this.$store.muted = !this.$store.muted;
    },

    setPlayerMute() {
        if (this.playerInstance) {
            this.playerInstance.muted = this.$store.muted;
            this.playerInstance.volume = this.$store.muted ? 0 : 1;
        }
    },

    onFocus() {
        if (this.isActivePlayer) {
            this.setPlayState();
        }
    },

    onBlur() {
        this.pausePlayer();
    },

    get isActivePlayer() {
        return this.galleryMode
            ? this.activeGallerySlider
            : this.$store.activePlayer === this.blockId;
    },

    activatePlayer() {
        this.$store.activePlayer = this.blockId;
    },

    deactivatePlayer() {
        if (this.$store.activePlayer === this.blockId) {
            this.$store.activePlayer = null;
        }
    },

    catchPlayError(error) {
        if (
            typeof error !== undefined &&
            typeof error.name !== undefined &&
            error.name === 'NotAllowedError'
        ) {
            this.$store.muted = true;
            this.setPlayerMute();
            this.setPlayState();
        }
    },
});
