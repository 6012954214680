export default () => ({
    expanded: false,

    toggle(updateAutoHeight = false) {
        this.expanded = !this.expanded;

        if (updateAutoHeight) {
            setTimeout(() => {
                this.$dispatch('update-height');
            }, 200);
        }
    },
});
