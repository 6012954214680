import anime from 'animejs';

export default () => ({
    open: false,
    showLocations: false,
    showContact: false,

    init() {
        this.$watch('open', async () => {
            if (this.open) {
                this.show();
                this.openLocations();
                this.openContact();
                await this.$nextTick();
                document.activeElement.blur();
            }
        });

        this.$watch('showLocations', () => {
            this.openLocations();
        });

        this.$watch('showContact', () => {
            this.openContact();
        });
    },

    show(selector = '[data-menu-item]') {
        anime.set(selector, {
            translateX: '100%',
            opacity: 0,
        });
        anime({
            targets: selector,
            translateX: 0,
            opacity: 1,
            duration: 700,
            easing: 'easeOutElastic(1, 1.5)',
            delay: anime.stagger(20),
        });
    },

    toggleLocations() {
        this.showLocations = !this.showLocations;
    },

    toggleContact() {
        this.showContact = !this.showContact;
    },

    openLocations() {
        if (this.open && this.showLocations) {
            this.show('[data-location-item]');
        }
    },

    openContact() {
        if (this.open && this.showContact) {
            this.show('[data-contact-item]');
        }
    },
});
