import Swiper from 'swiper';
import { Keyboard, Zoom } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/zoom';

export default (mode, slideIds = null, hasVideo = false) => ({
    open: false,
    swiperInitialised: false,
    slideIds,
    hasVideo,
    currentSlide: 0,
    currentSlideBlockId: null,
    opened: false,
    scrollbarWidth: 0,
    pausedPlayer: null,
    isGalleryMode: mode === 'media',
    isLetterMode: mode === 'letter',

    init() {
        this.$watch('open', () => {
            if (!this.swiperInitialised && this.open) {
                this.initSwiper();
            }

            if (this.open) {
                this.setScrollbarWidth();
                this.pauseAllPlayers();
                this.slideToCurrent();
                this.setCurrentSlide();

                setTimeout(() => {
                    this.opened = true;
                    this.$nextTick(() => {
                        document.activeElement.blur();
                        if (!this.isGalleryMode) {
                            this.$refs.panel.scrollTo(0, 0, {
                                behavior: 'smooth',
                            });
                        }
                    });
                }, 100);
            } else {
                this.opened = false;
                this.currentSlideBlockId = null;

                this.$nextTick(() => {
                    this.restartPlayer();
                });
            }

            if (this.isGalleryMode) {
                this.setThemeColor();
            }
        });
    },

    setScrollbarWidth() {
        this.scrollbarWidth =
            window.innerWidth - document.documentElement.clientWidth;
        if (this.scrollbarWidth) {
            this.scrollbarWidth += 'px';
        }
    },

    destroy() {
        if (this.swiper) {
            this.swiper.destroy();
        }
    },

    initSwiper() {
        if (this.swiperInitialised) {
            return;
        }

        const rewindMode = this.hasVideo;

        this.swiper = new Swiper(this.$refs.swiper, {
            modules: [Keyboard, Zoom],
            zoom: true,
            keyboard: {
                enabled: true,
            },

            loop: !rewindMode && this.isGalleryMode,
            rewind: rewindMode,

            slidesPerView: 1,
            spaceBetween: 0,
            autoHeight: !this.isGalleryMode,
            initialSlide: this.currentSlide,
        });

        this.swiper.on('slideChange', () => {
            this.setCurrentSlide();
            this.swiper.zoom.out();
        });

        this.setCurrentSlide();

        this.swiperInitialised = true;
    },

    setCurrentSlide() {
        this.currentSlide = this.swiper ? this.swiper.realIndex : 0;

        if (this.slideIds) {
            this.currentSlideBlockId = this.slideIds[this.currentSlide];
        }
    },

    slideToCurrent() {
        if (this.swiper) {
            this.swiper.slideTo(this.currentSlide);
        }
    },

    pauseAllPlayers() {
        this.pausedPlayer = this.$store.activePlayer;
        this.$store.activePlayer = null;
    },

    restartPlayer() {
        this.$store.activePlayer = this.pausedPlayer;
    },

    next() {
        if (this.swiper) {
            this.swiper.slideNext();
        }
    },

    prev() {
        if (this.swiper) {
            this.swiper.slidePrev();
        }
    },

    openSlideById(id) {
        let index = this.slideIds.indexOf(id);
        if (index > -1) {
            this.currentSlide = index;
        }
        this.open = true;
    },

    updateHeight() {
        if (this.swiper) {
            this.swiper.updateAutoHeight(200);
        }
    },

    setThemeColor() {
        const metaThemeColor = document.querySelector('meta[name=theme-color]');
        metaThemeColor.setAttribute(
            'content',
            this.open ? '#000000' : '#FFFFFF',
        );
    },

    get showPrevButton() {
        return this.isGalleryMode || this.currentSlide > 0;
    },

    get showNextButton() {
        return this.isGalleryMode || this.currentSlide < this.slideIds.length - 1;
    },
});
