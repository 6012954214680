import './bootstrap.js';
import './swup.js';

import Alpine from 'alpinejs';
import ui from '@alpinejs/ui';
import collapse from '@alpinejs/collapse';
import focus from '@alpinejs/focus';
import intersect from '@alpinejs/intersect';

import map from './components/map';
import accordion from './components/accordion';
import slider from './components/slider';
import player from './components/player';
import menu from './components/menu';

window.Alpine = Alpine;
Alpine.plugin(ui);
Alpine.plugin(collapse);
Alpine.plugin(focus);
Alpine.plugin(intersect);

document.addEventListener('alpine:init', () => {
    Alpine.data('map', map);
    Alpine.data('accordion', accordion);
    Alpine.data('slider', slider);
    Alpine.data('player', player);
    Alpine.data('menu', menu);

    Alpine.store('muted', 'true');
    Alpine.store('activePlayer', 'null');
    Alpine.store('playPositions', { x: null });
    Alpine.store('introRan', false);
});

Alpine.start();
